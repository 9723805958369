






































































































































































import GameStateSlot from '@/pages/Game/components/GameStateSlot.vue';
import { Component, Vue, Ref } from 'vue-property-decorator';

@Component
export default class D6GameStateSlot extends GameStateSlot {

  public get force() {
    const extraField = this.getExtraField('d6-force');
    return extraField.currentValue;
  }
  public get pv() {
    const extraField = this.getExtraField('d6-pv');
    return extraField.currentValue;
  }
  public get damage() {
    const extraField = this.getExtraField('d6-damage');
    return extraField.currentValue;
  }
}
