


























































































































































































































































































import DefaultGameSystem from '@/pages/Player/components/DefaultGameSystem.vue';
import { Component, Vue, Ref } from 'vue-property-decorator';

@Component
export default class EscapeGameSystem extends DefaultGameSystem {
  public get time() {
    const extraField = this.getExtraField('time-to-finish');
    const date = new Date();
    date.setSeconds(parseInt(extraField.currentValue)); // specify value for SECONDS here
    return date.toISOString().substr(11, 8);

  }
}
