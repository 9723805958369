
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "layer" })
export default class Layer extends Vue {

  @Prop() readonly layer: any; // TODO layer
  @Prop() readonly element!: boolean;

  created() {
    // console.log('layer', this.layer);
  }
}
