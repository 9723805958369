



























































































































































































import { GameMenuSlot } from '@/store/models/game.menu.slot';
import { GameState } from '@/store/models/game.state'; // TODO models outside the store ?
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class GameStateSlot extends Vue {
  @Prop() public readonly gameMenuSlot: GameMenuSlot | undefined;
  @Prop() public readonly number: number | undefined;

  public play() {
    this.$emit('play', this.number);
  }

  public async deleteGame() {
    const res = await this.$defaultConfirm('Effacer cette partie?');
    if (!res && !!this.gameState) {
      this.$emit('deleteGame', this.gameState.id);
    }
  }

  public get gameState() {
    return !!this.gameMenuSlot ? this.gameMenuSlot.gameState : null;
  }

  public get step() {
    return !!this.gameMenuSlot ? this.gameMenuSlot.currentStep : null;
  }

  protected getExtraField(typeName: string): any {
    if (!!this.gameState && !!this.gameState.extraFields) {
      return this.gameState.extraFields.filter((e) => e.type === typeName)[0];
    }
    return {};
  }
}
