


























































































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import DefaultStep from '@/pages/Player/components/DefaultStep.vue';
import { Power4, TweenMax, TimelineMax, gsap, Elastic, Bounce } from 'gsap';

@Component
export default class StepFailed extends DefaultStep {

  @Ref() public readonly lottieBackupAnimationRef!: HTMLElement;
  @Ref() public readonly lottieRestartAnimationRef!: HTMLElement;

  private backupAnim: any;
  private restartAnim: any;

  private displayBackupAnim = false;
  private displayRestartAnim = false;

  public loadBackup() {
    this.displayBackupAnim = true;
    setTimeout(() => {
      gsap.to(this.lottieBackupAnimationRef, {
            duration: 1,
            ease: Bounce.easeOut,
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            onComplete: () => {
                this.backupAnim.goToAndStop(40, true);
                this.backupAnim.setDirection(-1);
                this.backupAnim.play();
                this.backupAnim.onEnterFrame = (frame: any) => {
                    if (frame.currentTime == 0) { 
                      // this.backupAnim.destroy();
                      this.doAction('use-backup', {});
                    }
                };
            }});

    });
  }

  public restart() {
      this.displayRestartAnim = true;
      setTimeout(() => {
        gsap.to(this.lottieRestartAnimationRef, {
              duration: 1,
              ease: Bounce.easeOut,
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              onComplete: () => {

              }});
        this.restartAnim.goToAndPlay(0, true);
        this.restartAnim.onComplete = () => {
          this.doAction('restart', {});
        };
      });
    }

  public backupControl(anim: any) {
    this.backupAnim = anim;
    this.backupAnim.stop();
  }

  public restartControl(anim: any) {
    this.restartAnim = anim;
    this.restartAnim.stop();
  }
}
