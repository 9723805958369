
import { Component, Prop, Vue, Ref } from 'vue-property-decorator';

@Component
export default class ConfirmBox extends Vue {

    @Prop({ default: false}) public readonly dark!: boolean;

    @Prop({ default: 'default.yes'}) public readonly yes!: string;
    @Prop({ default: 'default.no' }) public readonly no!: string;

    private dialog = false;
    private resolve: any = null;
    private reject: any = null;

    private message = '';
    private title = '';
    private options = {
      width: 400,
      zIndex: 200,
      noconfirm: false,
    };

    public open(title: string, message: string, options: any) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    }

    public agree() {
      this.resolve(true);
      this.dialog = false;
    }
    public cancel() {
      this.resolve(false);
      this.dialog = false;
    }
}
