export default [{
    path: '/creator',
    component: () => import('@/pages/Creator/Creator.vue'), // lazy-loading
    meta: { hasRole: 'ADMIN' },
    children: [
      {
        path: '',
        name: 'creatorAdventureList',
        component: () => import('@/pages/Creator/Adventure/List.vue'),
        meta: { hasRole: 'ADMIN' },
      },
      {
        path: ':uniqueURI',
        component: () => import('@/pages/Creator/Adventure/Adventure.vue'),
        children: [
          {
            path: '',
            component: () => import('@/pages/Creator/Adventure/Edit.vue'),
            children: [
              {
                path: '',
                name: 'creatorAdventureDetail',
                meta: { hasRole: 'ADMIN' },
                component: () => import('@/pages/Creator/Adventure/Edit/General.vue'),
              },
              {
                path: 'objects',
                name: 'creatorAdventureObjects',
                meta: { hasRole: 'ADMIN' },
                component: () => import('@/pages/Creator/Adventure/Edit/Objects.vue'),
              },
              {
                path: 'design',
                name: 'creatorAdventureDesign',
                meta: { hasRole: 'ADMIN' },
                component: () => import('@/pages/Creator/Adventure/Edit/Design.vue'),
              },  
            ],
          },
          {
            path: 'step',
            component: () => import('@/pages/Creator/Adventure/Step.vue'),
            children: [
              {
                path: ':stepNumber',
                name: 'creatorAdventureSteps',
                meta: { hasRole: 'ADMIN' },
                component: () => import('@/pages/Creator/Adventure/Step/General.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/v1',
    component: () => import('@/pages/V1/V1.vue'), // lazy-loading
    meta: { hasRole: 'ADMIN' },
    children: [
      { path: '', redirect: 'creator' },
      {
        path: 'creator',
        name: 'v1Creator',
        component: () => import('@/pages/V1/Creator/Creator.vue'),
        meta: { hasRole: 'ADMIN' },
      },
    ],
}];