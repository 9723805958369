
import Vue from 'vue';

// load game system specific modules
// inspired by https://www.vuemastery.com/conferences/vueconf-us-2018/7-secret-patterns-vue-consultants-dont-want-you-to-know-chris-fritz/ at 4:30
// or could be done https://medium.com/scrumpy/dynamic-component-templates-with-vue-js-d9236ab183bb instead of loop in main.ts -->
const requireComponent = require.context(
    '../extensions', true, /[\w]+\.vue$/,
);

// console.log('trying to load game system extensions', requireComponent);
requireComponent.keys().forEach((pathName) => {
    // Get component config
    const componentConfig = requireComponent(pathName);

    // Get PascalCase name of component
    const fileName = pathName.substring(pathName.lastIndexOf('/') + 1, pathName.lastIndexOf('.'));

    const componentName = fileName.replace( /([a-z0-9])([A-Z])/g, '$1-$2' ).toLowerCase();

    // console.log('register', componentName, componentConfig);

    // Register component globally
    Vue.component(componentName, componentConfig.default || componentConfig);
});
