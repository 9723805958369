import Vue from 'vue';
import Vuex from 'vuex';

import './registerServiceWorker';

import '@/config/vue-addons';
import '@/config/hellojs';
import '@/extensions/extensions';

import App from './App.vue';
import router from './config/router';
import Default from './layouts/Default.vue';
import Empty from './layouts/Empty.vue';
import i18n from './config/i18n';
import Api from '@/Api';
import vuetify from './config/vuetify';
import moment from 'moment';
import Nl2br from 'vue-nl2br';

import './main.scss'; // declare it after vuetify ; see comment inside

// gsap plugin
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

import ConfirmBox from './components/ConfirmBox.vue';
import LottieAnimation from '@/components/LottieAnimation.vue'; // fix the event

// mosha toaster
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
    // You can set your default options here

    closeButton: !window.hasOwnProperty("cordova"),
    showCloseButtonOnHover: true,
    transition: 'Vue-Toastification__fade', // Vue-Toastification__bounce, Vue-Toastification__slideBlurred,
    pauseOnHover: true,
    hideProgressBar: false
};
Vue.use(Toast, options);

// add components
Vue.component('nl2br', Nl2br);
Vue.component('default-layout', Default);
Vue.component('empty-layout', Empty);
Vue.component('confirm-box', ConfirmBox);
Vue.component('lottie-animation', LottieAnimation);

Vue.config.productionTip = process.env.NODE_ENV === 'development'; // or false

// init api from local storage
Api.init();

// global errorHandler
Vue.config.errorHandler = (error) => {
  const message = i18n.t('error.message').toString();
  // console.log('## error', error);
  Vue.prototype.$notify(error, 'error', null, 10000);
};

// in another file ?
Vue.filter('localFormat', (date: moment.MomentInput, format: string | undefined) => {
  // @see to i18n https://stackoverflow.com/questions/45879215/global-locale-for-moment-js-in-a-vuejs2-project
  moment.locale('fr');
  return moment.utc(date).local().format(format);
});
Vue.filter('localFromNow', (date: moment.MomentInput) => {
  moment.locale('fr');
  return moment.utc(date).local().fromNow();
});

// create a store
const store = new Vuex.Store({});


// init new Vue instance
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
