
import Vue from 'vue';
import authModule from '../store/modules/authModule';
import i18n from './i18n';
import { Condition } from '../store/models/condition';

/* tslint:disable */ // Disable all rules for the rest of the file

// Add to Vue instances ; cf global.d.ts too
// to have a good context in this, use this syntax, and not this one (cf. doc) : () => {..}
Vue.prototype.$handleApiException = function(exception: any, scope?: string) {
    if (!!exception.response && !!exception.response.data && !!exception.response.data.errors) {
      exception.response.data.errors.forEach((error: any) => {
        this.$validator.errors.add({ field: error.field, msg: error.message, scope });
      });

      // TODO V1 compatibility to remove
    } /*else if (!!exception.response && !!exception.response.data && !!exception.response.data.messages) {
      const messages = exception.response.data.messages;
      Object.entries(messages).forEach((item) => {
        this.$validator.errors.add({ field: item[0], msg: (item[1] as string[]).join(", "), scope });
      });
    } */ else {
      throw exception;
    }
  };

Vue.prototype.publicPath = process.env.BASE_URL;

Vue.prototype.isCordova = window.hasOwnProperty("cordova");

Vue.prototype.$setFieldDirty = function(fieldName:string, scopeName?: string) {
  if (!!scopeName && !this.fields['$' + scopeName]) {
      return false;
  }
  const fields = !!scopeName ? this.fields['$' + scopeName] : this.fields;
  fields[fieldName].dirty = true;
}

Vue.prototype.$isFormDirty = function(scopeName: string) {
    if (!!scopeName && !this.fields['$' + scopeName]) {
        return false;
    }
    const fields = !!scopeName ? this.fields['$' + scopeName] : this.fields;
    // console.log('fields', fields);
    return Object.keys(fields).some((key) => fields[key].dirty);
};

// TODO rename formDirty but a mandatory is not here
Vue.prototype.$allRequiredAreDirty = function(scopeName: string) {
  if (!!scopeName && !this.fields['$' + scopeName]) {
    return false;
  }
  const fields = !!scopeName ? this.fields['$' + scopeName] : this.fields;
  console.log('fields', this.fields);
  return Object.keys(fields).some((key) => fields[key].dirty) && 
    !Object.keys(fields).filter(key => fields[key].required).some((key) => {
      console.log('key', key, fields[key]);
      return fields[key].valid === false;
    });
};

Vue.prototype.$isFieldDirty = function(fieldName: string, scopeName: string) {
  if (!fieldName || !!scopeName && !this.fields['$' + scopeName]) {
      return false;
  }
  const fields = !!scopeName ? this.fields['$' + scopeName] : this.fields;
  return !!fields[fieldName] && fields[fieldName].dirty;
};

Vue.prototype.$isAuthenticated = function() {
  return authModule.isAuthenticated;
};

Vue.prototype.$hasRole = function(roleName: string) {
  return authModule.hasRole(roleName);
};


import Toast from '@/components/Toast.vue';
Vue.prototype.$notify = function(msg: string, type?: string, title?: string, duration?: number) {

    // https://github.com/Maronato/vue-toastification/tree/main
    const options = {
      type: 'info',
      timeout: 5000,
      closeButton: false
    };
    msg = i18n.t(msg).toString();
    if (!!title) {
      title = i18n.t(title).toString();
    }
    if (!!type) {
      options.type = type;
    }
    if (!!duration) {
      options.timeout = duration;
    }
    
    Vue.prototype.$toast({
      component: Toast,
      props: { title, msg}
    }, options);
}

Vue.prototype.$defaultConfirm = function(textKey: string, titleKey?: string, yesButton?: string, noButton?: string) {
  const text = this.$i18n.tc(textKey);
  const title = !!titleKey ? this.$i18n.tc(titleKey) : this.$i18n.tc('default.confirm-title');
  // const buttonTrueText = !!yesButton ? this.$i18n.tc(yesButton) : this.$i18n.tc('default.yes');
  // const buttonFalseText = !!noButton ? this.$i18n.tc(noButton) : this.$i18n.tc('default.no');
  return this.$refs.confirm.open(title, text);
};

Vue.filter('conditions', (conditions: Condition[]) => {

  let builder = i18n.t('condition.if');  // TODO replace by an array, and join 
  let firstOr = true;
  // group by key
  const sortedConditions = conditions.reduce((hash:any, obj) => ({...hash, [obj.groupNumber]:( hash[obj.groupNumber] || [] ).concat(obj)}), {});
  for (const [groupNumber, subConditions] of Object.entries(sortedConditions)) {
      if (firstOr) {
          firstOr = !firstOr;
      } else {
          builder += i18n.t('condition.or').toString(); 
      }
      let firstAnd = true;
      // @ts-ignore
      for (const [subgroupNumber, subCondition] of Object.entries(subConditions)) {
          if (firstAnd) {
              firstAnd = !firstAnd;
          } else {
              builder += i18n.t('condition.and').toString();
          }
          // @ts-ignore
          builder += i18n.t(subCondition.type, {'attributeName': subCondition.attributeName, 'value': subCondition.value}).toString();
      }
  }

  return builder;
});