



















































































































































































































































































































































































import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';

import DefaultStep from '@/pages/Player/components/DefaultStep.vue';
import { GameStep } from '@/store/models/game.step';
import { ActionForm } from '@/store/forms/action.form';
import { Object as AttrObject } from '@/store/models/object';
import { Power2, Bounce, Elastic, TweenMax, TimelineMax, gsap, Back } from 'gsap';
import Layer from './Layer.vue';

import PinchScrollZoom, { PinchScrollZoomEmitData } from '@coddicat/vue-pinch-scroll-zoom';

import gameModule from '@/store/modules/gameModule';


import NProgress from 'nprogress';

@Component({
  name: 'default-game-system',
  components: { DefaultStep, Layer, PinchScrollZoom },
})
export default class DefaultGameSystem extends Vue {

  @Prop() public readonly gamestep!: GameStep;
  @Prop() public readonly testmode!: boolean;

  @Ref() public readonly newStep!: HTMLElement;
  @Ref() public readonly oldStep!: HTMLElement;
  @Ref() public readonly gamespace!: HTMLElement;

  @Ref() public readonly notebookButton!: HTMLElement;
  @Ref() public readonly objectsButton!: HTMLElement;
  @Ref() public readonly avatar!: HTMLElement;

 // private objectsAnim: any;


  private currentGamestep: GameStep | null = null;
  private selectedObject: AttrObject | null = null;

  private darkmode = false;
  private fsenabled = false;
  private fsmode = false;

  private dialogObject = false;
  private dialogNotebook = false;
  private dialogExamineObject = false;

  private examinedObject: any|null = null;
  private scale = 2;


  private drawer = false;
  private drawerObjects = false;
  private group = null;
  private notebook:any[] | null = [];

  private newNotebookEntry = false;
  private newFeatures = 0;
  private newObjects = 0;
  private notebookAnim: any;

  private windowWidth = 0;
  private windowHeight = 0;

  public created() {
    // TODO maybe we should use the STORE INSTEAD !!!!!!
    this.loadNewGameStep();

    // @ts-ignore  // @ts-ignore
    this.fsenabled = (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled);

    // @ts-ignore
    window.addEventListener('resize', this.windowSizeListener);
  }

  public beforeDestroy() {

    // @ts-ignore
    window.removeEventListener('resize', this.windowSizeListener);
  }

  public windowSizeListener() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
  }

  public choosePath(path: any) {
    NProgress.start();

    this.$emit('choosepath', path);
  }

  public updateGameStep() {
    this.$emit('updategamestep');
  }

  public doAction(action: ActionForm) {
    this.$emit('doaction', action);
  }

  public darkMode() {
    this.darkmode = !this.darkmode;
  }

  public fsMode() {
    // @ts-ignore
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
      // @ts-ignore
      const requestFullScreen = this.gamespace.requestFullscreen || this.gamespace.webkitRequestFullscreen || this.gamespace.mozRequestFullScreen || this.gamespace.msRequestFullscreen;
      requestFullScreen.call(this.gamespace);
    } else {
      // @ts-ignore
      const exitFullScreen = document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen;
      exitFullScreen.call(document);
    }
  }


  public quit() {

    if (this.$isAuthenticated()) {
      this.$router.push('/play/' + this.$route.params.uniqueURI);
    } else {
      this.$router.push('/');
    }
  }


  public notebookControl(anim: any) {
    this.notebookAnim = anim;
    this.notebookAnim.goToAndPlay(0); // before it disappears
  }

  public examineObject(item: any) {

    this.dialogExamineObject = true;
    this.examinedObject = item;

    setTimeout(() => {
      // reset values
    (this.$refs.zoomer as PinchScrollZoom).setData({
      scale: 1,
      originX: 0,
      originY: 0,
      translateX: 0,
      translateY: 0
    });
    }, 0);
  }

  @Watch('gamestep')
  public onGameStepChanged(val: GameStep, oldVal: GameStep) {

    this.newNotebookEntry = false;
    this.newFeatures = 0;
    this.newObjects = 0;
    NProgress.done();

    // if it's the same, no animation
    if (
      val.currentStep.numero == oldVal.currentStep.numero
      && val.gameState.state != 'FAILED'
    ) {
      this.loadNewGameStep();
      return;
    }


    // copy old step
    const width = this.newStep.getBoundingClientRect().width;
    const height = this.newStep.getBoundingClientRect().height;
    this.oldStep.innerHTML = '';
    this.oldStep.append(this.newStep.cloneNode(true));

    // load new step
    this.loadNewGameStep();

    // transition of the old step
    gsap.set(this.oldStep, {
      left: 0,
      top: 0,
      opacity: 1,
      width,
      display: 'block',
    });
    gsap.to(this.oldStep, {
      duration: 1,
      ease: Power2.easeOut,
      left: window.innerHeight < window.innerWidth ? 0 : -width - 30,
      top: window.innerHeight < window.innerWidth ? -height - 30 : 0,
      onComplete: (el) => {
        this.oldStep.style.display = 'none';
      },
    });
    // transition of the new step
    gsap.set(this.newStep, { opacity: 0 });
    gsap.to(this.newStep, {
      duration: 1,
      opacity: 1,
      onComplete: (el) => {

      },
    });

    // scroll view
    gsap.to(this.gamespace, {
      duration: 1,
      ease: Back.easeOut,
      scrollTo: 0,
      onComplete: (el) => {

      }
    });

  }

  public stepComponent(gamestep: GameStep) {
    if (gamestep.gameState.state == 'FAILED') {
      return 'step-failed';
    }
    const defaultComponentName = 'default-step'; // TODO load 'default' components as components outside extension folder
    const componentName =
      !!gamestep && gamestep.gameState && gamestep.currentStep
        ? gamestep.currentStep.type
        : defaultComponentName;
    if (Vue.options.components[componentName]) {
      return componentName;
    }
    return defaultComponentName;
  }

  public selectedObjectPickable() {
    if (this.selectedObject != null) {
      const id = this.selectedObject.id;
      return !!this.gamestep.objects && this.gamestep.objects.filter((o) => o.id === id).length > 0;
    }
    return false;
  }

  public async showNotebook() {
    this.notebook = await gameModule.loadNotebook(this.gamestep.gameState.id);
    this.dialogNotebook = true;
    if (!!this.notebookAnim && !!this.newNotebookEntry) {
      this.notebookAnim.goToAndPlay(0); // before it disappears
    }
    // this.newNotebookEntry = false; // pas obligatoire de faire ça..
  }

  public pickup() {
    if (this.selectedObject != null) {
      // TODO doAction should disable all buttons and links maybe !?
      const data = {id: this.selectedObject.id };
      this.doAction({ nameAction:'pick-up', data });
      this.dialogObject = false;
    }
  }

  public async dropWithConfirm(item:any) {
    const res = await this.$defaultConfirm('Reposer cet objet ?');
    if (res) {
      this.selectedObject = item;
      this.drop();
    } else {
      setTimeout(() => {
        this.drawerObjects = true;
      }, 0);
    }
  }

  public drop() {
    if (this.selectedObject != null) {
      // TODO doAction should disable all buttons and links maybe !?
      const data = {id: this.selectedObject.id };
      this.doAction({ nameAction:'drop', data });
      this.dialogObject = false;
    }
  }

  protected getExtraField(typeName: string): any {
    if (!!this.gamestep && !!this.gamestep.gameState.extraFields) {
      return this.gamestep.gameState.extraFields.filter((e) => e.type === typeName)[0];
    }
    return {};
  }

  private bounceAttributes() {
    if (this.gamestep.gameState.notebookEntries > 0 && (!this.currentGamestep || this.currentGamestep.gameState.notebookEntries != this.gamestep.gameState.notebookEntries)) {
      this.newNotebookEntry = true;
      setTimeout(() => {gsap.to(this.notebookButton, {
        duration: 0.7,
        ease: Bounce.easeOut,
        scale: 1.5,
        onComplete: (el) => {
          gsap.to(this.notebookButton, {
            duration: 0.3,
            ease: Back.easeOut,
            scale: 1
          });
        },
      });
      });
    }
    if (this.gamestep.gameState.features.length > 0 && (!this.currentGamestep || this.currentGamestep.gameState.features.length != this.gamestep.gameState.features.length)) {
      const current = !this.currentGamestep ? 0 : this.currentGamestep.gameState.features.length;
      this.newFeatures = this.gamestep.gameState.features.length - current;
      setTimeout(() => {gsap.to(this.avatar, {
        duration: 0.7,
        ease: Bounce.easeOut,
        scale: 1.5,
        onComplete: (el) => {
          gsap.to(this.avatar, {
            duration: 0.3,
            ease: Back.easeOut,
            scale: 1
          });
        },
      });
      });
    }
    if (this.gamestep.gameState.objects.length > 0 && (!this.currentGamestep || this.currentGamestep.gameState.objects.length != this.gamestep.gameState.objects.length)) {
      const current = !this.currentGamestep ? 0 : this.currentGamestep.gameState.objects.length;
      this.newObjects = this.gamestep.gameState.objects.length - current;
      setTimeout(() => {gsap.to(this.objectsButton, {
        duration: 0.5,
        ease: Bounce.easeOut,
        scale: 1.5,
        onComplete: (el) => {
          gsap.to(this.objectsButton, {
            duration: 0.7,
            ease: Back.easeOut,
            scale: 1
          });
        },
      });
      });
    }
  }

  private loadNewGameStep() {
    if (!!this.gamestep) {
      this.bounceAttributes();
      this.currentGamestep = JSON.parse(JSON.stringify(this.gamestep));
      this.displayNotifications();
    }
  }

  private displayNotifications() {
    if (!this.currentGamestep || !this.currentGamestep.notifications) {
      return;
    }
    /* let time = 0;
    this.currentGamestep.notifications.forEach((notif) => {
      setTimeout(() => {
        this.$notify(!!notif.description ? notif.description : '', 'info', notif.name, 10000);

      }, time);
      time += 500;
    }); */
  }
}
