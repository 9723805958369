<template>
  <div
    v-if="style"
    ref="lavContainer"
    :style="style"
  />
</template>

<script>
import lottie from "lottie-web";

export default {
  props: {
    path: {
      required: true
    },
    speed: {
      type: Number,
      required: false,
      default: 1
    },
    width: {
      type: Number,
      required: false,
      default: -1
    },
    height: {
      type: Number,
      required: false,
      default: -1
    },
    loop: {
      type:Boolean,
      required: false,
      default: true
    },
    autoplay: {
      type:Boolean,
      required: false,
      default: true
    },
    loopdelaymin: {
      type: Number,
      required: false,
      default: 0
    },
    loopdelaymax: {
      type: Number,
      required: false,
      default: 0
    },
    settings: {
      type: Object,
      preserveAspectRatio: "xMidYMid slice",
      clearCanvas: true,
      progressiveLoad: false,
      hideOnTransparent: true
    },
  },
  data: () => ({
    name: 'lottie-animation',
    anim: null,
    style: null
  }),
  /* watch: {
    path: function(newVal, oldVal) {
      this.init();
    }
  }, */
  mounted() {
    this.init();
  },
  methods: {
    

    async loadJsonData(path) {
      // need this plugin too : https://www.npmjs.com/package/cordova-plugin-xhr-local-file
      return new Promise(function(resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open('get', "/" + path, true);
        xhr.responseType = 'json';
        xhr.onload = function() {
          var status = xhr.status;
          if (status == 200 || status == 0) {
            resolve(xhr.response);
          } else {
            reject(status);
          }
        };
        xhr.send();
      });
    },
    async init() {
      this.style = {
        width: (this.width != -1 )? `${this.width}px` : '100%',
        height: (this.height != -1 )? `${this.height}px` : '100%',
        overflow: "hidden",
        margin: "0 auto"
      };

      let jsonData = await this.loadJsonData(this.path);

      if(this.anim) {
        this.anim.destroy(); // Releases resources. The DOM element will be emptied.
      }

      this.anim = lottie.loadAnimation({
        container: this.$refs.lavContainer,
        renderer: "svg",
        loop: this.loop,
        autoplay: this.autoplay,
        animationData: jsonData,
        rendererSettings: this.settings
      });

      // this file is a copy of the officiel lottie-vuejs with the fixed event :
      //this.$emit("AnimControl", this.anim);
      this.$emit("anim-control", this.anim);

      this.anim.setSpeed(this.speed);
      if (this.loopdelaymin > 0) {
        this.anim.loop = false;
        this.anim.autoplay = false;
        this.executeLoop();
      }
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    },
    executeLoop() {
      this.anim.play();
      setTimeout(() => {
        this.anim.stop();
        this.executeLoop();
      }, this.getRandomInt(this.loopdelaymin, this.loopdelaymax == 0? this.loopdelaymin : this.loopdelaymax));
    },


  }
};
</script>