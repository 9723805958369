import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=55cfa667&scoped=true"
import script from "./Home.vue?vue&type=script&lang=ts"
export * from "./Home.vue?vue&type=script&lang=ts"
import style0 from "./Home.scss?vue&type=style&index=0&id=55cfa667&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55cfa667",
  null
  
)

/* custom blocks */
import block0 from "./i18n.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fleny%2FWorkspace%2Flaventurier%2Flaventurier-ui%2Fsrc%2Fpages%2FHome%2FHome.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports