import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css';

import i18n from '../config/i18n';
import Vuetify, { VSnackbar, VBtn, VIcon, VTooltip, VBadge } from 'vuetify/lib';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VTooltip,
    VBadge,

  },
});

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    t: (key: any, ...params: any) => i18n.t(key, params) as string,
  },
});

export default vuetify;

