
// in hello.phonegap.js, 
// if (/^file:\/{3}[^/]/.test(window.location.href) && window.cordova) {
// => the first part forbid us to test in dev mode
const GOOGLE_APP_CLIENT_ID: any = process.env.VUE_APP_GOOGLE_CLIENT_ID;
const FACEBOOK_APP_CLIENT_ID: any = process.env.VUE_APP_FACEBOOK_CLIENT_ID;
const VUE_APP_REDIRECT_URL: any = process.env.VUE_APP_REDIRECT_URL;
import HelloJs from 'hellojs';

// chromium-browser --disable-web-security
// to run without security to check the functionality
// (TODO documentate it)
HelloJs.init(
  {
    google: GOOGLE_APP_CLIENT_ID,
    facebook: FACEBOOK_APP_CLIENT_ID,
  },
  {
    redirect_uri: 'https://www.laventurier.fr/auth/redirect.html', // pour du mobile mettre :  https://www.laventurier.fr/auth/redirect.html ; sinon localhost:8080
  },
);
document.addEventListener("deviceready", onDeviceReady, false);
function openWindow(url?: string | URL | undefined, target?: string | undefined, features?: string | undefined):Window|null {
    if (!features) {
        features = 'location=yes,hidden=yes,hideurlbar=yes';
    }
    // @ts-ignore
    return window.cordova.InAppBrowser.open(url, target, features);
}
function onDeviceReady() {
  if (window.hasOwnProperty("cordova")) {
    // @ts-ignore
        window.open = openWindow; //window.cordova.InAppBrowser.open;
  }
}
