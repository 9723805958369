import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

import Api from '@/Api';
import store from '@/store';
import { GameStep } from '../models/game.step';
import { StepForm } from '../forms/step.form';
import { ChoosePathForm } from '../forms/choose.path.form';
import { ActionForm } from '@/store/forms/action.form';
import { GameForm } from '../forms/game.form';

@Module({ generateMutationSetters: true })
export class GameModule extends VuexModule {

  // state
  private _gameStep: GameStep | null = null;
  private _notebook = [];
  private _notebookEntries = 0;

  // actions

  @Action
  public async choosePath(choosePathForm: ChoosePathForm) {
    const response = await Api.$http.post('/game/' + choosePathForm.gameStateId + '/path/' + choosePathForm.choice);
    this._gameStep = response.data;
  }

  @Action
  public async doAction(actionForm: ActionForm) {
    if (!!this._gameStep) {
      const response = await Api.$http.post('/game/' + this._gameStep.gameState.id + '/action/' + actionForm.nameAction, actionForm.data);
      this._gameStep = response.data;
    }
  }

  @Action
  public async loadPrivateLink(privateLink: string) {
    const response = await Api.$http.post('/private/' + privateLink);
    this._gameStep = response.data;
  }

  @Action
  public async loadTestStep(stepForm: StepForm) {
    const response = await Api.$http.post('/test/' + stepForm.uniqueURI + '/' + stepForm.stepNumber);
    this._gameStep = response.data;
  }

  @Action
  public async playGame(gameForm: GameForm) {
    const response = await Api.$http.post('/play/' + gameForm.uniqueURI + '/' + gameForm.slotNumber);
    this._gameStep = response.data;
  }

  @Action
  public async deleteGame(gameStateId: string) {
    const response = await Api.$http.delete('/game/' + gameStateId);
    this._gameStep = null;
  }

  @Action
  public async playDemo(uniqueURI: string) {
    const response = await Api.$http.post('/demo/' + uniqueURI);
    this._gameStep = response.data;
  }

  @Action
  public async loadNotebook(gameStateId: string) {
    if (this._gameStep == null) {
      return null;
    }
    if (this._gameStep.gameState.notebookEntries !== this._notebookEntries) {
      const response = await Api.$http.get('/game/' + gameStateId + '/notebook');
      this._notebook = response.data;
      this._notebookEntries = this._gameStep.gameState.notebookEntries; // or _notebook.length
    }
    return this._notebook;
  }

  // methods non impacting the store

  // getters

  public get gameStep() {
    return this._gameStep;
  }

  public get gameState() {
    return !!this._gameStep ? this._gameStep.gameState : null;
  }

  public get currentStep() {
    return !!this._gameStep ? this._gameStep.currentStep : null;
  }
}

// register module
export default new GameModule({ store, name: 'gameModule' });
