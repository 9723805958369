



















































































































































































































































































































































































































































































































































































































































































































































import DefaultStep from '@/pages/Player/components/DefaultStep.vue';
import { GameStep } from '@/store/models/game.step';
import { Power2, Bounce, Elastic, TweenMax, TimelineMax, gsap, Back } from 'gsap';
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';

import gameModule from '@/store/modules/gameModule';

@Component
export default class D6StepBattle extends DefaultStep {

  // dans cette étape les actions ne rafraichissent pas automatiquement le gamestep ;
  // on passe donc par un un intermédiaire, moduleGamestep qui représente l'état courant du module
  private get moduleGamestep() {
    return gameModule.gameStep;
  }

  public get force() {
    return !!this.getExtraFieldFromModule('d6-force') ? this.getExtraFieldFromModule('d6-force').currentValue : 0;
  }

  public get damage() {
    return !!this.getExtraFieldFromModule('d6-damage') ? this.getExtraFieldFromModule('d6-damage').currentValue : 0;
  }

  public get oppForce() {
    return !!this.getExtraFieldFromModule('d6-force-monster') ? this.getExtraFieldFromModule('d6-force-monster').currentValue : 0;
  }

  public get oppDamage() {
    return !!this.getExtraFieldFromModule('d6-damage-monster') ? this.getExtraFieldFromModule('d6-damage-monster').currentValue : 0;
  }

  @Ref('dice1') public readonly dice1!: HTMLElement;
  @Ref('dice2') public readonly dice2!: HTMLElement;
  @Ref('dice3') public readonly dice3!: HTMLElement;
  @Ref('dice4') public readonly dice4!: HTMLElement;

  protected swordSettings: any = {
    preserveAspectRatio: 'xMidYMax slice',
    clearCanvas: true,
    progressiveLoad: false,
    hideOnTransparent: true,
  };
  private battleDialog = false;

  private displayAnim = false;
  private attackAnim: any;

  private extraDamage = [];
  private playerBonus: any[] = [];
  private player: any[] = [];
  private playerResults!: any[];
  private total: number = 0;
  private pv!: number;
  private pvPercent!: number;
  private pvColor!: string;

  private oppResults!: any[];
  private oppTotal: number = 0;
  private oppPv!: number;
  private oppPvPercent!: number;
  private oppPvColor!: string;

  public created() {
    this.updateValues();
  }

  /* not working
  public mounted() {
    setTimeout(() => {

         // bounce hearts
        gsap.to(this.$refs.heart, {
            duration: 1.5,
            y: -20,
            repeat: -1,
            yoyo: true
        });
    }, 0);
  } */

  @Watch('moduleGamestep')
  public onModuleGameStepChangedInBattle(val: GameStep, oldVal: GameStep) {
    // TODO animate dices, then updateValues
    const result = this.getExtraFieldFromModule('d6-battle-result');
    if (!result || !result.currentValue) {
      return;
    }

    // player
    this.player = result.initValue.split('#');
    this.playerResults = this.player[0].split(/(?=[-+])/);

    // opponent
    this.oppResults = result.currentValue.split(/(?=[-+])/);

    this.revertDice(this.dice1);
    this.revertDice(this.dice2);
    this.revertDice(this.dice3);
    this.revertDice(this.dice4);

    setTimeout(() => {
      this.turnDice(this.dice1, this.playerResults[1][2]);
      this.turnDice(this.dice2, this.playerResults[2][2]);
      this.turnDice(this.dice3, this.oppResults[1][2]);
      this.turnDice(this.dice4, this.oppResults[2][2]);
    }, 300);

    setTimeout(() => {
      this.updateValues();
    }, 600);
  }

  public attack() {
    this.playerBonus = [];
    this.extraDamage = [];
    this.displayAnim = true;
    this.attackAnim.goToAndPlay(0);
    this.attackAnim.onComplete = () => {
      //  this.doAction("d6_battle", {});
      this.displayAnim = false;
    };
    // this.doAction("d6_battle", {});
    setTimeout(() => {
      const newGamestep = this.doAction('d6_battle', {}, true);
    }, 750);
  }

  public attackControl(anim: any) {
    this.attackAnim = anim;
    this.attackAnim.stop();
  }

  public fuir() {
    this.battleDialog = false;
    this.total = 0;
    this.oppTotal = 0;
    this.extraDamage = [];
    this.playerBonus = [];
    this.turnDice(this.dice1, 1);
    this.turnDice(this.dice2, 1);
    this.turnDice(this.dice3, 1);
    this.turnDice(this.dice4, 1);
    this.updateGameStep();
  }

  private getExtraFieldFromModule(typeName: string): any {
    if (!!this.moduleGamestep && !!this.moduleGamestep.gameState.extraFields) {
      return this.moduleGamestep.gameState.extraFields.filter(
        (e) => e.type === typeName,
      )[0];
    }
    return {};
  }

  private updateValues() {
    // opponent
    let extraField = this.getExtraFieldFromModule('d6-pv-monster');
    this.oppPv = parseInt(extraField.currentValue);
    let result = (this.oppPv * 100) / parseInt(extraField.initValue);
    this.oppPvPercent = Math.round(result);
    this.oppPvColor = this.getPvColor(this.oppPvPercent, 'brown');

    // you
    extraField = this.getExtraFieldFromModule('d6-pv');
    this.pv = parseInt(extraField.currentValue);
    result = (this.pv * 100) / parseInt(extraField.initValue);
    this.pvPercent = Math.round(result);
    this.pvColor = this.getPvColor(this.pvPercent, 'teal');

    // animate the results
    this.playerBonus = this.playerResults;
    this.animateNumber(
      {
        animateTotal: this.countTotal(this.playerResults),
        el: this,
        field: 'total',
        interval: null,
      },
      () => {
        let damage = this.damage;
        if (!!this.player && this.player.length == 2) {
          this.extraDamage = this.player[1].split(/(?=[-+])/);
          damage = this.countTotal(this.extraDamage) + parseInt(this.damage);
          if (damage < 0) { damage = 0; }
        }
        if (this.total != 0) {
          if (this.pv <= 0) {
            this.$notify('Vous êtes mort :(');
          } else if (this.oppPv <= 0) {
            this.$notify('Vous triomphez !');
          } else if (this.total == this.oppTotal) {
            this.$notify('Personne ne prend l\'ascendant');
          } else if (this.total >= this.oppTotal) {
            this.$notify(
              'Vous infligez ' + damage + ' dégâts a votre adversaire',
            );
          } else {
            this.$notify(
              'L\'adversaire vous inflige ' + this.oppDamage + ' dégâts',
            );
          }
        }

        // ici on peut faire les chargements asynchrones, et faire des animations avant de charger l'étape de fin..

        setTimeout(() => {}, 10000);
      },
    );
    this.animateNumber(
      {
        animateTotal: this.countTotal(this.oppResults),
        el: this,
        field: 'oppTotal',
        interval: null,
      },
      null,
    );

    this.$forceUpdate();
  }

  private getPvColor(percent: Number, defaultColor: string) {
    if (percent > 50) { return defaultColor; }
    // if (percent > 20) return "orange";
    // if (percent > 0) return "red";
    if (percent > 0) { return 'orange'; }
    return 'cyan';
  }

  private animateNumber(
    params: { animateTotal: number; el: any; field: string; interval: any },
    callback: any,
  ) {
    clearInterval(params.interval);
    params.interval = window.setInterval(() => {
      if (params.animateTotal != params.el[params.field]) {
        let change = (params.animateTotal - params.el[params.field]) / 10;
        change = change >= 0 ? Math.ceil(change) : Math.floor(change);
        params.el[params.field] += change;
      } else {
        if (!!callback) { callback(); }
        clearInterval(params.interval);
      }

      // this.$forceUpdate();
    }, 50);
  }

  private countTotal(field: any[]) {
    return !!field
      ? field.reduce((result, item) => {
          const subResult = parseInt(item.replace(/[\+|\-|d]/g, ''));
          if (item[0] === '-') {
            return result - subResult;
          }
          return result + subResult;
        }, 0)
      : 0;
  }

  private getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  private turnDice(dice: HTMLElement, number: number) {
    // @see https://icodemag.com/3d-rolling-dice-css-javascript/
    const showClass = 'show-' + number;
    dice.className = 'cube';
    dice.classList.add(showClass);
  }

  private revertDice(dice: HTMLElement) {
    let i = 1;
    while (i <= 6) {
      if (dice.classList.contains('show-' + i)) {
        break;
      }
      i++;
    }
    let random = this.getRandomInt(1, 7);
    while (random == i) {
      random = this.getRandomInt(1, 7);
    }
    this.turnDice(dice, random);
  }
}
