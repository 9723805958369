
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {

  get layout() {
    return (this.$route?.meta?.layout || 'default') + '-layout';
  }
}
