






















































































































































































import { Component, Prop, Watch, Vue, Ref } from "vue-property-decorator";

import { GameStep } from "@/store/models/game.step";

import Layer from "./Layer.vue";

@Component({ name: "default-step", components: { Layer } })
export default class DefaultStep extends Vue {
  @Prop() readonly gamestep!: GameStep;
  @Prop() readonly testmode!: boolean;
  @Prop() readonly darkmode!: boolean;
  @Prop() readonly newnotebookentry!: boolean;
  @Prop() readonly newfeatures!: number;
  @Prop() readonly newobjects!: number;

  private cheat = false;
  private selection = null;
  private choiceDisabled = false;

  protected fullscreenSettings:any = {
      preserveAspectRatio: "xMidYMin slice",
      clearCanvas: true,
      progressiveLoad: false,
      hideOnTransparent: true
  }

  @Watch("gamestep")
  onGameStepChanged(val: GameStep, oldVal: GameStep) {
    /* if (this.selection != null) {
      // @ts-ignore
      this.$refs.listPath[this.selection].$el.style.background = "none"; // remove hover style programmtically ; could do this with css too
    } */
    this.selection = null;
    this.choiceDisabled = false; //
  }

  public doAction(nameAction: string, data:any = {}, preventLoading = false) {
    this.$emit("doaction", { nameAction, data, preventLoading });
  }

  public choosePath(path: any) {
    this.choiceDisabled = true; // TODO do it for all actions at upper level with a variable in defaultgameSystem
    this.$emit("choosepath", path);
  }

  public updateGameStep() {
    this.$emit('updategamestep');
  }
  
  public get gameState() {
    return !!this.gamestep ? this.gamestep.gameState : null;
  }

  public get step() {
    return !!this.gamestep ? this.gamestep.currentStep : null;
  }

  public get paths() {
    if (!!this.gamestep && !!this.gamestep.paths) {
      return this.gamestep.paths.filter((path) => !path.forced || this.cheat);
    }
    return null;
  }

  protected getExtraField(typeName: string): any {
    if (!!this.gamestep && !!this.gamestep.gameState.extraFields) {
      return this.gamestep.gameState.extraFields.filter(
        (e) => e.type === typeName
      )[0];
    }
    return {};
  }

}
