export default [{
    path: '/play',
    component: () => import('@/pages/Parent.vue'),
    children: [
      {
        path: '',
        redirect: '/404',
      },
      {
        path: ':uniqueURI',
        component: () => import('@/pages/Parent.vue'),
        children: [
          // could be in game.ts
          {
            path: '',
            name: 'gameMenu',
            meta: { layout: 'empty' },
            component: () => import('@/pages/Game/GameMenu.vue'),
          },
          {
            path: ':slotNumber',
            name: 'gamePlayer',
            meta: { layout: 'empty' },
            component: () => import('@/pages/Player/Player.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/demo',
    component: () => import('@/pages/Parent.vue'),
    children: [
      {
        path: '',
        redirect: '/404',
      },
      {
        path: ':uniqueURI',
        name: 'gameDemo',
        meta: { layout: 'empty' },
        component: () => import('@/pages/Player/Player.vue'),
      },
    ],
  },
  {
    path: '/test',
    component: () => import('@/pages/Parent.vue'),
    meta: { hasRole: 'ADMIN' },
    children: [
      {
        path: '',
        redirect: '/404',
      },
      {
        path: ':uniqueURI',
        component: () => import('@/pages/Player/Player.vue'),
        meta: { layout: 'empty', hasRole: 'ADMIN', test: true },
      },
    ],
  },
  {
    path: '/private',
    component: () => import('@/pages/Parent.vue'),
    meta: { hasRole: 'ADMIN' },
    children: [
      {
        path: '',
        redirect: '/404',
      },
      {
        path: ':privateLink',
        component: () => import('@/pages/Player/Player.vue'),
        meta: { layout: 'empty', hasRole: 'ADMIN', test: true },
      },
    ],
}];
