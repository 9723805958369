import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import VeeValidate from 'vee-validate';
import deepmerge from 'deepmerge';


// @ts-ignore
import english from 'vee-validate/dist/locale/en';

// @ts-ignore
import french from 'vee-validate/dist/locale/fr';

Vue.use(VueI18n);

const browserLocale = navigator.language.split('-')[0] || 'en';

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      if (!messages[locale]) {
        messages[locale] = locales(key);
      } else {
        // @ts-ignore
        messages[locale] = deepmerge.all([messages[locale], locales(key)]);
      }
    }
  });
  return messages;
}

const i18n = new VueI18n({
  // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  locale: browserLocale, // browser instead
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});

Vue.use(VeeValidate, {
  i18nRootKey: 'validations', // customize the root path for validation messages.
  i18n,
  dictionary: {
   en: english,
   fr: french,
  },
});

export default i18n;

