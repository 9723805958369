import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

import Api from '@/Api';
import store from '@/store';
import Utils from '@/utils';
import { LoginForm, RegisterForm, SocialLoginForm, ResetPasswordForm } from '../forms/auth.form';

@Module({ generateMutationSetters: true })
export class AuthModule extends VuexModule {

  // state
  private roles: string[] = [];

  // actions
  @Action
  public async logout() {
    Api.clearTokens();
    const roles: string[] = [];
    this.roles = roles;
    return Api.$http.post('/auth/logout');
  }

  @Action
  public async updateAuthorizations(token: string) {
    const decodedToken = Utils.decodeToken(token);
    this.roles = decodedToken.authorities.split(',');
  }

  @Action
  public async signin(loginForm: LoginForm) {
    return Api.$noAuthHttp.post('/auth/signin', loginForm);
  }

  @Action
  public async register(registerForm: RegisterForm) {
    return Api.$noAuthHttp.post('/auth/register', registerForm);
  }

  @Action
  public async oauthSignin(oauthSigninForm: SocialLoginForm) {
    return Api.$noAuthHttp.post('/auth/oauthSignin', oauthSigninForm);
  }

  @Action
  public async previousAdmin() {
      return Api.$http.post('/auth/previousAdmin');
  }

  public async validateResetPassword(passwordForm: ResetPasswordForm) {
    return Api.$noAuthHttp.post('/auth/reset/password/validate', passwordForm);
  }

  public async initResetPassword(email: string) {
    return Api.$noAuthHttp.post('/auth/reset/password/init', {email});
  }

  get hasRole() {
   return (roleName: string) => {
     return !!this.roles && this.roles.filter((r) => r === roleName).length > 0;
   };
  }

  get isAuthenticated(): boolean {
    // console.log('this.roles', this.roles);
    return this.roles.length > 0;
  }
}

// register module
export default new AuthModule({ store, name: 'authModule' });



