export default [{
    path: '/admin',
    component: () => import('@/pages/Admin/Admin.vue'), // lazy-loading
    meta: { hasRole: 'ADMIN' },
    children: [
      {
        path: '',
        name: 'Users',
        component: () => import('@/pages/Admin/User/User.vue'),
        meta: { hasRole: 'ADMIN' },
      },
      {
        path: 'adventures',
        name: 'Adventures',
        component: () => import('@/pages/Admin/Adventure/Adventure.vue'),
        meta: { hasRole: 'ADMIN' },
      },
    ],
  }];