import axios, { AxiosInstance } from 'axios';
import router from '@/config/router';
import authModule from '@/store/modules/authModule';
import Vue from 'vue';
import i18n from './config/i18n';

class Api {

  private _http: AxiosInstance;
  private _noAuthHttp: AxiosInstance;
  private isRefreshing = false;
  private failedQueue: any[] = [];

  constructor() {

    const baseURL = (!!process.env.VUE_APP_API_PATH ? process.env.VUE_APP_API_PATH : '');

    window.baseURL = baseURL; // TODO ONLY FOR DEPRECATED

    // construct specific axios instance
    this._http = axios.create({
      baseURL,
      timeout: 100000,
      headers: {
        'Content-Type': 'application/json',
      }/*,
      params: {
        t: new Date().getTime()
      }*/
    });

    // specific axios instance for refresh calls and other calls w/o authentication
    this._noAuthHttp = axios.create({
      baseURL,
      timeout: 100000,
      headers: {
        'Content-Type': 'application/json',
      }/*,
      params: {
        t: new Date().getTime()
      }*/
    });

    // add interceptors
    this._http.interceptors.response.use((resp) => {
      return this.handleResponse(resp);
    }, (error) => {
      return this.handleRetryError(error);
    });
    this._noAuthHttp.interceptors.response.use((resp) => {
      return this.handleResponse(resp);
    }, (error) => {
      return this.handleError(error);
    });

  }

  public init() {
    // init from localStorage
    const accessToken = localStorage.getItem('access-token');
    this.updateAuthorizationHeader(accessToken);
    if (!!accessToken) {
      authModule.updateAuthorizations(accessToken);
    }
    return this;
  }

  public get $http() {
    return this._http;
  }

  public get $noAuthHttp() {
    return this._noAuthHttp;
  }

  public clearTokens() {
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
  }

  public updateTokens(accessToken: string, refreshToken: string) {
    localStorage.setItem('access-token', accessToken);
    localStorage.setItem('refresh-token', refreshToken);
    this.updateAuthorizationHeader(accessToken);
  }

  private async refresh() {

    this.isRefreshing = true;

    const refreshToken = localStorage.getItem('refresh-token');
    if (!refreshToken) {
      // -> arrive si 401 sur le refresh token ; ex. on se reconnecte après que le refreshToken ait expiré
      // des loggers ont été ajoutés côté backend pour voir précisément le cas
      throw new Error('no refresh token found');
    }
    return this.$noAuthHttp.post('auth/refresh', { refreshToken }).then((resp) => {
      this.updateTokens(resp.data.accessToken, resp.data.refreshToken);
      authModule.updateAuthorizations(resp.data.accessToken);
      this.processQueue(null, resp.data.accessToken);
      return resp;
    }).finally(() => {
      this.isRefreshing = false;
    });
  }

  private updateAuthorizationHeader(accessToken: string | null) {
    if (!!accessToken) {
      this._http.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
    } else {
      delete this._http.defaults.headers.common.Authorization;
    }
  }

  private handleResponse(response: any) {
    // automatically detect if there is tokens, and refresh the context
    if (!!response && response.data && !!response.data.accessToken && response.data.refreshToken) {
      this.updateTokens(response.data.accessToken, response.data.refreshToken);
      authModule.updateAuthorizations(response.data.accessToken);
    }
    return response;
  }

  private handleRetryError(error: any) {
    if (!!error.response && error.response.status === 401 && !error.config._retry) {
      if (this.isTokenExpiredError(error)) {
        return this.resetTokenAndReattemptRequest(error);

      } else {
        authModule.logout();
        router.push('/auth/login');
        this.clearTokens();
        return Promise.reject(i18n.t('Vous avez été déconnecté par sécurité'));
      }
    } else {
      return this.handleError(error);
    }
  }

  private handleError(error: any) {
    if (!!error.response && error.response.status === 429) {
      const content = i18n.t('Vous avez dépassé votre nombre limite de demandes').toString();
      Vue.prototype.$notify(content, 'error', null, 5000);
    }
    return Promise.reject(error);
  }

  private isTokenExpiredError(error: any): boolean {
    return !!error.response.data && error.response.data.indexOf('JWT expired') >= 0;
  }

  private processQueue = (error: any, token = null) => {
    this.failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.failedQueue = [];
  }

  private async resetTokenAndReattemptRequest(error: any) {
    const originalRequest = error.config;

    if (this.isRefreshing) {
      return new Promise((resolve, reject) => {
        this.failedQueue.push({ resolve, reject });
      }).then((token) => {
        originalRequest.headers.Authorization = 'Bearer ' + token;
        return this._http(originalRequest);
      }).catch((err) => {
        return Promise.reject(err);
      });
    }

    return this.refresh().then((resp: any) => {
      originalRequest.headers.Authorization = 'Bearer ' + resp.data.accessToken;
      return this._http(originalRequest);
    }).catch((err) => {
      authModule.logout();
      router.push('/auth/login');
      this.clearTokens();
      this.processQueue(err, null);
      return Promise.reject(i18n.t('Vous avez été déconnecté par sécurité'));
    });
  }
}

export default new Api();
