




































































































































































































































































































































import DefaultGameSystem from '@/pages/Player/components/DefaultGameSystem.vue';
import { Component, Vue, Ref } from 'vue-property-decorator';

@Component
export default class D6GameSystem extends DefaultGameSystem {
  public get force() {
    const extraField = this.getExtraField('d6-force');
    return extraField.currentValue;
  }
  public get pv() {
    const extraField = this.getExtraField('d6-pv');
    return extraField.currentValue;
  }
  public get damage() {
    const extraField = this.getExtraField('d6-damage');
    return extraField.currentValue;
  }
}
