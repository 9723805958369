
import { Component, Watch, Vue } from 'vue-property-decorator';
import authModule from '@/store/modules/authModule';

@Component
export default class AppNavBar extends Vue {
  private drawer = null;
  private newCreatorUI = true;

  public created() {
    this.newCreatorUI = !this.isV1Creator;
  }

  public logout() {
    authModule.logout().then(() => {
      if (this.$route.path !== '/') { this.$router.push('/'); }
      this.drawer = null;
      Vue.prototype.$notify('navbar.logout-confirm', 'info', null, 1000);
    });
  }

  public toPreviousAdmin() {
    authModule.previousAdmin().then(() => {
        this.$router.push('/admin');
        this.drawer = null;
    });
  }

  // Declared as computed property getter
  get isCreator() {
    return !!this.$route && this.$route.path.indexOf('/creator') >= 0 ? true : false;
  }
  get isV1Creator() {
    return !!this.$route && window.location.href.indexOf('/v1') >= 0 ? true : false;
  }

  @Watch('newCreatorUI')
  public onNewAdminSwitch(val: boolean, oldVal: boolean) {
    // const newUrl = this.$route.path.indexOf('/v1') == 0 ? this.$route.path.substring(3) : '/v1' + this.$route.path;
    const newUrl = this.$route.path.indexOf('/v1') == 0 ? '/creator' : '/v1/creator';
    this.$router.push({ path: newUrl });
  }
}
