









































































































































































































import DefaultStep from '@/pages/Player/components/DefaultStep.vue';
import { Component, Vue, Ref } from 'vue-property-decorator';

@Component
export default class StepPassword extends DefaultStep {
  private password: string = '';
  private vaultAnim: any;

  public submitPassword() {
    // peut-être avoir une notification à chaque appel
    this.vaultAnim.goToAndPlay(0);
    this.vaultAnim.onComplete = () => {
      this.doAction('password', { value: this.password });
    };
  }

  public vaultControl(anim: any) {
    this.vaultAnim = anim;
    this.vaultAnim.stop();
  }

  public get succeed() {
    if (!!this.gamestep && !!this.gamestep.gameState.extraFields) {
      return this.gamestep.gameState.extraFields.filter((e) => e.type == 'password' && e.currentValue == 'SUCCEED').length > 0;
    }
    return false;
  }
}
