
import { Component, Prop, Vue } from 'vue-property-decorator';
import adventureModule from '@/store/modules/adventureModule';
import { Adventure } from '@/store/models/adventure';

@Component({ name: 'game-list' })
export default class GameList extends Vue {
  private adventures: Adventure[] = [];

  public async created() {
    await adventureModule.loadAdventures(); // TODO in a creatorModule
    this.adventures = { ...adventureModule.adventures };
  }

  public play(uniqueURI: string) {
    if (this.$isAuthenticated()) {
      this.$router.push('/play/' + uniqueURI);
    } else {
      this.$router.push('/demo/' + uniqueURI);  // TODO !!
    }
  }
}
