import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

import Api from '@/Api';
import store from '@/store';
import { Adventure } from '../models/adventure';

@Module({ generateMutationSetters: true })
export class AdventureModule extends VuexModule {

  // state
  private _adventures: Adventure[] = [];

  // actions
  @Action
  public async loadAdventures() {
    const response = await Api.$http.get('/adventure');
    this._adventures = response.data;
  }

  // methods non impacting the store

  public loadAdventure(uniqueURI: string) {
    return Api.$http.get('/adventure/' + uniqueURI);
    
  }

  // getters

  public get adventures() {
    return this._adventures;
  }

}

// register module
export default new AdventureModule({ store, name: 'adventureModule' });


