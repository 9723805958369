import Vue from 'vue';
import Router, { Route } from 'vue-router';
import authModule from '@/store/modules/authModule';

import 'nprogress/nprogress.css';
import NProgress from 'nprogress';

import { Component } from 'vue-property-decorator';

import creator from '@/config/routes/creator';
import admin from '@/config/routes/admin';
import player from '@/config/routes/player';
import home from '@/config/routes/home';
import game from '@/config/routes/game';
import vuetify from '@/config/vuetify';

Vue.use(Router);

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);
const base = [
   {
    path: '/auth/login',
    name: 'login',
    component: () => import('@/pages/Auth/Login.vue'),
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import('@/pages/Auth/Register.vue'),
  },
  {
    path: '/auth/reset/password/:email',
    name: 'resetPassword',
    component: () => import('@/pages/Auth/ResetPassword.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/pages/User/Profile.vue'),
  },
  {
    path: '*',
    name: '404*',
    component: () => import('@/pages/NotFound/NotFound.vue'),
  },
];

let routes;
if (window.hasOwnProperty("cordova") && false) { // TODO add a criteria : AND we have a sql local db detected
  routes = [...base, ...player, ...game];
}
else {
  routes = [...base, ...creator, ...admin, ...player, ...home];
} 

const router = new Router({
  mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: any) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach((to: Route, from: Route) => {
  NProgress.done();
});

router.beforeEach((to: Route, from: Route, next: any) => {
  if (authModule.isAuthenticated && (to.path.startsWith('/auth/'))) {
    next('/');
  } else {
    const hasRole = to?.meta?.hasRole;
    if (!!hasRole && !authModule.hasRole(hasRole)) {
      if (!authModule.isAuthenticated) {
        next('/auth/login');
      } else {
        next('/');
      }
      return;
    }
    next();
  }
});

export default router;
